<template>
  <div class="redirect-container" data-test="redirect-to-webadmin">
    <a
      ref="goBackLink"
      class="redirect-back-link"
      data-test="redirect-go-back-link"
      @click.prevent.stop="emit('back-to-webadmin')"
    >
      <img :src="iconArrowLeft" alt="Arrow Left" class="icon-left" />
      {{ t('go_back_to_login') }}
    </a>
    <h1 class="cy-title q-my-none q-mb-sm " data-test="redirect-heading">
      {{ t('redirect_title') }}
    </h1>
    <p class="text-ws-base110 small q-mb-md" data-test="redirect-text">
      {{ t('want_to_access_to_admin_text') }}
    </p>
    <ws-button
      class="cy-login-btn ws-typo-button"
      data-test="redirect-to-webadmin-button"
      :primary="true"
      :rounded="isTheme(Theme.ACTIVE24)"
      :href="getUrl('webadmin')"
    >
      {{ t('login_to_webadmin') }}
    </ws-button>
  </div>
</template>

<script setup lang="ts">
import WsButton from '@/tmp_uikit/component-3/WsButton.vue';
import iconArrowLeft from '@loopia-group/assets/dist/icons/arrow_left.svg';
import {useI18n} from 'vue-i18n';
import getUrl from '@/services/urls';
import { isTheme, Theme } from '@/services/theming';

const $i18n = useI18n();
const {t} = $i18n;

const emit = defineEmits(['back-to-webadmin']);
</script>

<style lang="scss" scoped>
.redirect {
  &-container {
    display: flex;
    flex-direction: column;
    padding-top: 24px;

    .ws-button-wrapper {
      align-self: end;
    }
  }

  &-back-link {
    color: $ws-navy-100;
    font-size: var(--ws-typo-small);
    line-height: 16px;
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 0;
    width: fit-content;
    margin-bottom: 8px;

    .icon-left {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}

.theme--loopia {
  .ws-button-wrapper:deep(a.q-btn.ws-button:not([class*=' text-'])) {
    color: $loopia-plum;
  }
}
</style>
