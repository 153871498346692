import { isProduction } from '@/services/config';
import { Country } from './countries.enum';

export function getAllowedLanguages(country: Country) {
  let langs;

  if (country === Country.Sweden) {
    langs = [
      'en-SE',
      'sv-SE',
    ];
  } else {
    langs = [
      'cs-CZ',
      'hu-HU',
      'en-US',
      'sk-SK',
    ];
  }

  // Add crowdin option
  if (!isProduction()) {
    langs.push('xx-XX');
  }

  return langs;
}
