import { get } from 'lodash-es';
import { Country } from '@/services/countries.enum';
import { Theme } from '@/services/theming';

export enum Company {
  WEBSUPPORT = 'ws',
  LOOPIA = 'lp',
  ACTIVE24 = 'a24',
}

// TODO: maybe find a prettier way to check for production?
export const isProduction = () =>
  (
    window.location.origin.includes('mail.websupport.')
    ||
    window.location.origin.includes('mail.loopia.')
    ||
    window.location.origin.includes('mail.active24.')
  )
  && !window.location.origin.includes('.odin');

const apiUrl = () =>
  isProduction()
    ? 'https://api-webmail.websupport.sk/'
    : 'https://webmail-login-api.staging.websupport.sk/';
export const defaultConfig = () => ({
  apiUrl: apiUrl(),
  defaultLang: 'en-US',
  country: Country.Slovakia,
  theme: Theme.CARY,
  company: Company.WEBSUPPORT,
});

const config = () => ({
  se: {
    ...defaultConfig(),
    defaultLang: 'sv-SE',
    country: Country.Sweden,
  },
  se_lp: {
    ...defaultConfig(),
    defaultLang: 'sv-SE',
    country: Country.Sweden,
    theme: Theme.LOOPIA,
    company: Company.LOOPIA
  },
  sk: {
    ...defaultConfig(),
    defaultLang: 'sk-SK',
    country: Country.Slovakia,
  },
  cz: {
    ...defaultConfig(),
    defaultLang: 'cs-CZ',
    country: Country.Czechia,
  },
  cz_a24: {
    ...defaultConfig(),
    defaultLang: 'cs-CZ',
    country: Country.Czechia,
    theme: Theme.ACTIVE24,
    company: Company.ACTIVE24,
  },
  hu: {
    ...defaultConfig(),
    defaultLang: 'hu-HU',
    country: Country.Hungary,
  },
});

export function getConfig(): AppConfig {
  // Handle all local and testing URLs
  if (['http://127.0.0.1:5173', 'http://127.0.0.1:5050'].includes(window.location.origin)) {
    return get(config(), window.location.pathname.slice(1), config().sk);
  }

  // Handle country specific URLs
  switch (window.location.origin) {
  case 'http://webmail.ws-sk.odin':
  case 'https://mail.websupport.sk':
    return config().sk;

  case 'https://mail.websupport.cz':
    return config().cz;

  case 'http://webmail.a24-cz.odin':
  case 'https://mail.active24.cz':
  case 'https://mail.active24.eu':
    return config().cz_a24;

  case 'https://webmail.websupport.se':
    return config().se;

  case 'http://webmail.lp-se.odin':
  case 'https://webmail.loopia.se':
    return config().se_lp;

  case 'https://mail.websupport.hu':
    return config().hu;

  default:
    return defaultConfig();
  }
}

export interface AppConfig {
  defaultLang: string,
  country: Country,
  theme: Theme,
  company: Company,
  apiUrl: string,
}
