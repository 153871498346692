
import { get } from 'lodash-es';
import { Company, getConfig } from '@/services/config';
import i18n, { localeToFlagCode } from '@/i18n';


/****  Types  ****/

type WebsupportLanguages = {
  se: string,
  hu: string,
  sk: string,
  cz: string,
  en_se: string,
  en: string,
}
type LoopiaLanguages = {
  se: string,
  en_se: string,
}
type Active24Languages = {
  cz: string,
  hu: string,
  sk: string,
  en: string,
}

type CompanyLanguages = WebsupportLanguages | LoopiaLanguages | Active24Languages;

type CompanySpecific = {
  help: CompanyLanguages,
  contact: CompanyLanguages,
  terms: CompanyLanguages,
  privacy: CompanyLanguages
  webadmin: CompanyLanguages,
  webadmin_email: CompanyLanguages,
}
type GlobalUrls = {
  admin: CompanyLanguages,
  googleTermsOfService: CompanyLanguages,
  googlePrivacy: CompanyLanguages
};


/****  Implementation  ****/

const config = getConfig();

const wsBase: string = 'https://websupport';
const websupport: CompanySpecific = {
  help: {
    se: `${wsBase}.se/support`,
    hu: `${wsBase}.hu/tamogatas`,
    sk: `${wsBase}.sk/podpora`,
    cz: `${wsBase}.cz/podpora`,
    en_se: `${wsBase}.se/en/support`,
    en: `${wsBase}.se/en/support`,
  },
  contact: {
    se: `${wsBase}.se/support`,
    hu: `${wsBase}.hu/kapcsolat`,
    sk: `${wsBase}.sk/kontakt`,
    cz: `${wsBase}.cz/kontakt`,
    en_se: `${wsBase}.se/en/support`,
    en: `${wsBase}.se/en/support`,
  },
  terms: {
    se: `${wsBase}.se/om-websupport/avtalsvillkor`,
    hu: `${wsBase}.hu/aszf`,
    sk: `${wsBase}.sk/obchodne-podmienky`,
    cz: `${wsBase}.cz/obchodni-podminky`,
    en_se: `${wsBase}.se/en/about-websupport/terms-and-conditions`,
    en: `${wsBase}.se/en/about-websupport/terms-and-conditions`,
  },
  privacy: {
    se: `${wsBase}.se/om-websupport/dataskyddspolicy`,
    hu: `${wsBase}.hu/adatvedelem`,
    sk: `${wsBase}.sk/sukromie`,
    cz: `${wsBase}.cz/soukromi`,
    en_se: `${wsBase}.se/en/about-websupport/terms-and-conditions`,
    en: `${wsBase}.se/en/about-websupport/data-protection`,
  },
  webadmin: {
    se: 'https://auth.websupport.se/?s=admin&l=sv&c=websupport.se&account=standard',
    hu: 'https://login.websupport.hu/?s=admin&l=hu&c=websupport.hu&account=standard',
    sk: 'https://login.websupport.sk/?s=admin&l=sk&c=websupport.sk&account=standard',
    cz: 'https://login.websupport.cz/?s=admin&l=cs&c=websupport.cz&account=standard',
    en_se: 'https://auth.websupport.se/?s=admin&l=en&c=websupport.se&account=standard',
    en: 'https://auth.websupport.se/?s=admin&l=en&c=websupport.se&account=standard',
  },
  webadmin_email: {
    se: 'https://auth.websupport.se/?s=admin&l=sv&c=websupport.se&account=email',
    hu: 'https://login.websupport.hu/?s=admin&l=hu&c=websupport.hu&account=email',
    sk: 'https://login.websupport.sk/?s=admin&l=sk&c=websupport.sk&account=email',
    cz: 'https://login.websupport.cz/?s=admin&l=cs&c=websupport.cz&account=email',
    en_se: 'https://auth.websupport.se/?s=admin&l=en&c=websupport.se&account=email',
    en: 'https://auth.websupport.se/?s=admin&l=en&c=websupport.se&account=email',
  }
};

const loopiaBase: string = 'https://loopia';
const loopia: CompanySpecific = {
  help: {
    se: `${loopiaBase}.se/support`,
    en_se: `${loopiaBase}.com/support`,
  },
  contact: {
    se: `${loopiaBase}.se/support`,
    en_se: `${loopiaBase}.com/support`,
  },
  terms: {
    se: `${loopiaBase}.se/avtalsvillkor`,
    en_se: `${loopiaBase}.com/terms-and-conditions/`,
  },
  privacy: {
    se: `${loopiaBase}.se/omloopia/dataskydd/`,
    en_se: `${loopiaBase}.com/aboutloopia/data-protection/`,
  },
  webadmin: {
    se: 'https://www.loopia.se/loggain/',
    en_se: 'https://www.loopia.com/login/',
  },
  webadmin_email: {
    se: 'https://www.loopia.se/loggain/',
    en_se: 'https://www.loopia.com/login/',
  }
};

const active24Base: string = 'https://active24';
const active24: CompanySpecific = {
  help: {
    cz: `${active24Base}.cz/centrum-napovedy`,
    hu: `${active24Base}.cz/centrum-napovedy`,
    sk: `${active24Base}.cz/centrum-napovedy`,
    en: `${active24Base}.cz/centrum-napovedy`,
  },
  contact: {
    cz: `${active24Base}.cz/o-spolecnosti/kontakty`,
    hu: `${active24Base}.cz/o-spolecnosti/kontakty`,
    sk: `${active24Base}.cz/o-spolecnosti/kontakty`,
    en: `${active24Base}.cz/o-spolecnosti/kontakty`,
  },
  terms: {
    cz: `${active24Base}.cz/o-spolecnosti/obchodni-podminky`,
    hu: `${active24Base}.cz/o-spolecnosti/obchodni-podminky`,
    sk: `${active24Base}.cz/o-spolecnosti/obchodni-podminky`,
    en: `${active24Base}.cz/o-spolecnosti/obchodni-podminky`,
  },
  privacy: {
    cz: `${active24Base}.cz/o-spolecnosti/soukromi`,
    hu: `${active24Base}.cz/o-spolecnosti/soukromi`,
    sk: `${active24Base}.cz/o-spolecnosti/soukromi`,
    en: `${active24Base}.cz/o-spolecnosti/soukromi`,
  },
  webadmin: {
    cz: 'https://login.active24.cz/?s=admin&l=cs&c=active24.cz&account=standard',
    hu: 'https://login.active24.cz/?s=admin&l=hu&c=active24.cz&account=standard',
    sk: 'https://login.active24.cz/?s=admin&l=sk&c=active24.cz&account=standard',
    en: 'https://login.active24.cz/?s=admin&l=en&c=active24.cz&account=standard',
  },
  webadmin_email: {
    cz: 'https://login.active24.cz/?s=admin&l=cs&c=active24.cz&account=email',
    hu: 'https://login.active24.cz/?s=admin&l=hu&c=active24.cz&account=email',
    sk: 'https://login.active24.cz/?s=admin&l=sk&c=active24.cz&account=email',
    en: 'https://login.active24.cz/?s=admin&l=en&c=active24.cz&account=email',
  }
};

const googleTermsOfServiceBaseUrl: string = 'https://policies.google.com/terms?hl=';
const googlePrivacyBaseUrl: string = 'https://policies.google.com/privacy?hl=';

const adminBase: string = (() => {
  switch (config.company) {
  case Company.LOOPIA:
    return 'https://admin.loopia';
  case Company.WEBSUPPORT:
    return 'https://admin.websupport';
  case Company.ACTIVE24:
    return 'https://admin.active24';
  default:
    return 'https://admin.websupport';
  }
})();

const global: GlobalUrls = {
  // Maybe needs to be moved to the company specific part
  admin: {
    se: `${adminBase}.se`,
    hu: `${adminBase}.hu`,
    sk: `${adminBase}.sk`,
    cz: `${adminBase}.cz`,
    en_se: `${adminBase}.se/en`,
    en: `${adminBase}.se/en`,
  },
  googleTermsOfService: {
    se: `${googleTermsOfServiceBaseUrl}sv`,
    hu: `${googleTermsOfServiceBaseUrl}hu`,
    sk: `${googleTermsOfServiceBaseUrl}sk`,
    cz: `${googleTermsOfServiceBaseUrl}cs`,
    en_se: `${googleTermsOfServiceBaseUrl}en`,
    en: `${googleTermsOfServiceBaseUrl}en`,
  },
  googlePrivacy: {
    se: `${googlePrivacyBaseUrl}sv`,
    hu: `${googlePrivacyBaseUrl}hu`,
    sk: `${googlePrivacyBaseUrl}sk`,
    cz: `${googlePrivacyBaseUrl}cs`,
    en_se: `${googlePrivacyBaseUrl}en`,
    en: `${googlePrivacyBaseUrl}en`,
  }
};

const company: { [key: string]: CompanySpecific | GlobalUrls } = {
  ws: websupport,
  lp: loopia,
  global: global,
  a24: active24,
};

const getLangCode = (): string => {
  let urlLang = localeToFlagCode(i18n.global.locale.value).toLowerCase();

  // Swedish english
  if (config?.country === 'se' && urlLang === 'en') {
    urlLang += '_se';
  }

  return urlLang;
};

const getTLD = (url: string): string => {
  return new URL(url).hostname.split('.').pop() || '';
};

const replaceLanguage = (key: string, currentLanguage: string): string | undefined => {
  const tld: string = getTLD(window.location.href);
  const adminUrl = get(company, [config.company, key, tld]);

  if (!adminUrl) {
    return undefined;
  }

  let locale: string;
  switch (currentLanguage) {
  case 'en_se':
    locale = 'en';
    break;
  case 'se':
    locale = 'sv';
    break;
  case 'cz':
    locale = 'cs';
    break;
  default:
    locale = currentLanguage;
  }

  const url: URL = new URL(adminUrl);
  url.searchParams.set('l', locale);

  return url.toString();
};

export default function getUrl(key: string): string {
  const currentLanguage: string = getLangCode();
  const companySpecific = get(company, [config.company, key, currentLanguage]);

  if (key === 'webadmin' || key === 'webadmin_email') {
    const adminUrl = replaceLanguage(key, currentLanguage);

    if (adminUrl) {
      return adminUrl;
    }
  }

  if (companySpecific) {
    return companySpecific;
  }

  const generic = get(company, ['global', key, currentLanguage]);

  if (!generic) {
    console.error(`Url '${key}' not defined!`);
  }

  return generic;
}
